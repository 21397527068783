import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/next-core/next-core/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"600\",\"700\"],\"variable\":\"--font-source-sans-pro\"}],\"variableName\":\"sourceSansPro\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/core/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/next-core/next-core/workspaces/core/src/components/Elastic/ElasticClientInstrumentation.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/workspaces/ui/src/lib/BackgroundImage.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/next-core/next-core/node_modules/react-loading-skeleton/dist/skeleton.css");
